import axios, { AxiosResponse } from 'axios';


const instance = axios.create({
    baseURL: '/', // Set your API base URL
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const signIn = async (
  userName: string,
  password: string,
  userTechInfo: string | ""
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post('/api/user/signin', { userName, password, userTechInfo });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUser = async (
  token?: string,
): Promise<{token: string, user: any}> => {
  try {
    const response = await instance.get('/api/user/currentUser', {headers: {Authorization: `Bearer ${token}`}});
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signInAdmin = async (
  email: string,
  password: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post('/api/user/signin/superUser', { email, password });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerAdmin = async (
  email: string,
  name: string,
  password: string,
  adminType: number,
  token?: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post('/api/user/register/superUser', {  name, email, password, adminType }, {headers: {Authorization: `Bearer ${token}`}});
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersByType = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get('/api/user/getUsersByType', {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllUsers = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get('/api/user/getAllUsers', {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAdmin = async (
  token: string | undefined,
  userId: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/user/deleteAdmin?userId=${userId}`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllAdmins = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get('/api/user/getAllAdmins', {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllUserTasks = async (
  userId: string | undefined,
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/task/getUserTasks?userId=${userId}`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTasksCompletion = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/task/getTasksCompletion`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getZoomMeeting = async (
  userId: string | undefined,
  token: string | undefined,
  joinInPerson: boolean
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/task/getZoomMeeting?userId=${userId}&joinInPerson=${joinInPerson}`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersMeetingInfo = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/usersMeeting/get`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDyadMeetingZoomInfo  = async (
  token: string | undefined,
  dyadNumber: number | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/usersMeeting/get/${dyadNumber}`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getZoomMeetingRecording = async (
  token: string | undefined,
  userType: number | undefined,
  userId: string | undefined
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/task/getZoomMeetingRecording?userType=${userType}&userId=${userId}`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllResouceDoc = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/resource/getResources`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserProgressTasks = async (
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/task/getUserProgressTasks`, {
      headers: {
      Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};


export const createTaskApi = async (
  data: object,
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post('/api/task/completeTask', data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createLeadGuideApi = async (
  data: object,
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post('/api/leadguide/create', data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLeadGuideApi = async (
  userId: string | undefined,
  token: string | undefined,
  form_mod: string | undefined,
  isCP: boolean | undefined
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/leadguide/get?userId=${userId}&form_mod=${form_mod}&isCP=${isCP}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLeadGuideByUID = async (
  userId: string | undefined,
  activity: number,
  token: string | undefined
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.get(`/api/leadguide/getByUID?userId=${userId}&activity=${activity}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLeadGuideApi = async (
  data: object | undefined,
  token: string | undefined,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await instance.post(`/api/leadguide/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

